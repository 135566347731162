<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Tickets" subtitle="Manage the types of tickets that can be associated with permitted events." :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.scope" text="Status" tooltip="Select scope" :options="$util.options($constants.schedule.ticket.scopeName)" />
		<app-content-filters-checklist v-model="filter.cost" text="Sale" tooltip="Select sale" :options="$util.options($constants.schedule.ticket.purchaseName)" />
		<app-content-filters-select v-model="filter.sort" :alwaysLabel="true" :alwaysValue="true" text="Sort by" tooltip="Change sorting" :options="$util.options(sort)" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no tickets found.">

		<app-content-note name="tickets.creating">
		
			Only admins or schedule moderators, managed on the Settings page, can create ticketed events. Normal attendees will not see any mention of ticket types when creating their own events.
		
		</app-content-note>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column text="Use" />
				<app-table-column text="Sale" />
				<app-table-column border="left" align="center" text="Sold" />
				<app-table-column border="right" align="center" text="Capacity" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="$constants.schedule.ticket.scopeName[item.scope]" />
					<app-table-cell-text :text="$constants.schedule.ticket.purchaseName[item.purchase.enabled]" />
					<app-table-cell-text align="center" border="left" :text="item.count.active" />
					<app-table-cell-text align="center" border="right" :text="item.capacity.amount" v-if="item.capacity.enabled" />
					<app-table-cell-icon align="center" border="right" icon="unlimited" v-tooltip="'No limit set.'" v-if="!item.capacity.enabled" />

					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 120px 200px 80px 80px 24px',
			endpoint: 'convention/schedule/tickets',
			live: 'convention/schedule/tickets',
			edit: 'Convention.Schedule.Tickets.Edit',
			filter: {
				scope: [],
				cost: [],
				sort: 'name'
			},
			sort: {
				name: 'Name',
				sold: 'Sold',
				capacity: 'Capacity'
			}
		}

	}

}

</script>

<style scoped>

</style>